import { AppRoutes } from 'routes';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from 'providers/authprovider';
import { StringsProvider } from 'providers/stringsprovider';

function App() {
  return (
    <BrowserRouter>
      <StringsProvider>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </StringsProvider>
    </BrowserRouter>
  );
}

export default App;
