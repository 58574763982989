import React, { createContext, useState, useEffect, useMemo } from 'react';
import sv from 'locales/sv.json';
import da from 'locales/da.json';

// Define the StringsContext
const StringsContext = createContext();
const countryLanguageMap = {
  SE: 'sv',
  DK: 'da',
  FI: 'fi',
};

// StringsProvider Component
const StringsProvider = ({ children }) => {
  const [language, setLanguage] = useState('sv');
  const [strings, setStrings] = useState(sv);

  // Function to load strings based on language
  const loadStrings = (lang) => {
    switch (lang) {
      case 'da':
        setStrings(da);
        break;
      case 'sv':
        setStrings(sv);
        break;
      // case 'zh':
      //   setStrings(zh);
      //   break;
      default:
        setStrings(sv);
        break;
    }
  };

  useEffect(() => {
    const country = process.env.NEXT_PUBLIC_COUNTRY_CODE || 'SE';
    const detectedLanguage = countryLanguageMap[country] || 'sv';
    setLanguage(detectedLanguage);
    loadStrings(detectedLanguage);
  }, []);

  // Memoize the context value to optimize performance
  const memoizedValue = useMemo(
    () => ({
      language,
      strings,
      setLanguage: (lang) => {
        setLanguage(lang);
        loadStrings(lang);
      },
    }),
    [language, strings]
  );

  return (
    <StringsContext.Provider value={memoizedValue}>
      {children}
    </StringsContext.Provider>
  );
};

export { StringsContext, StringsProvider };
